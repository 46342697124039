<template>
  <Loading />
</template>

<script>
import "firebase/compat/auth";
import store from "../store/store.js";
import { getUserData } from "../../api/endpoints/user";
import Loading from "./Loading.vue";

export default {
  methods: {},
  components: {
    Loading
  },
  async created() {
    // if coming from an email sign in, we need to set the user in global state
    if (this.$route.params.user) {
      store.commit("setUser", JSON.parse(JSON.stringify(this.$route.params.user)));

      // get the user info so we can determine which screen to send them to
      const user = await getUserData(this.$route.params.user.uid);
      if (user && user.isAdmin) {
        this.$router.push({path: "/admin", query: { userId: user.id }});
      } else if (user) {
        this.$router.push({path: "/dashboard", query: { userId: user.id }});
      } else {
        // if not, kick 'em out
        this.$router.push("/");
      }
    } else {
      // if not, kick 'em out
      this.$router.push("/");
    }
  },
};
</script>
